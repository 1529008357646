.product-page {
    margin: 1vw 0;
    background-color: rgba(244, 204, 117, 0.2);
    padding: 1vw 2vw;
    border-radius: 5px;
}

.product-page__container {
    display: flex;
}

.product__column__small {
    flex: 10%;
}

.product-page__container__column_photo {
    display: flex;
    flex-direction: column;
    flex: 100%;
    /* margin-left: 10vw; */
    justify-content: center;
    align-items: center;

}

.product-page__container__column {
    flex: 40%;
    margin-left: 2vw;
    font-size: 16px;
}

.product-image-small {
    border-radius: .5vw;
    width: 120px;
    height: 150px;
    margin-bottom: 1vw;
    cursor: pointer;
}

.selected-image {
    border: 2px solid #efbb36;
}

.product-header {
    font-size: 2.5vw;
    border-bottom: 2px solid #efbb36;
}

.product-image {
    width: 60%;
    height: auto;
    max-height: 700px;
    border-radius: 5px;
    margin-bottom: 1vw;
}

.zoomed-image {
    width: 70vw;
    height: auto;
    max-height: 70vw;
}

.zoom-button {
    background-color: #ec5a16e6;
    border: none;
    color: #fff;
    font-size: 2.5vw;
    margin-left: 1vw;
    width: 80px;
    height: 50px;
    border-radius: .5vw;
    cursor: pointer;
}

.zoom-button:active {
    background-color: rgb(114, 76, 10);
}

.buttons-container {
    align-items: center;
    justify-content: center;    
    display: flex;
}

.cart-button {
    margin-left: 1.5vw;
    background-color: rgba(182, 126, 5, 0.666);
    padding: 1vw 2vw;
    color: #ffffff;
    border: none;
    border-radius: .5vw;
    font-size: 1.5vw;
    cursor: pointer;
}

.cart-button:active {
    background-color: rgba(206, 120, 7, 0.5);
}

.price-tag {
    font-size: 22px;
    margin-left: 2vw;
}

@media only screen and (max-width: 768px) {
    .product-header {
        font-size: 25px;
    }
    .product-page__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product-page__container__column {
        font-size: 16px;
        align-self: start;
        margin-left: 5vw;
    }
    .product-image {
        width: 85vw;
        height: auto;
    }
    .zoom-button {
        display: none;
    }
    .product-image-small {
        width: 100px;
        height: 130px;
        margin-left: 1px;
    }
    .price-tag {
        font-size: 12px;
    }
    .cart-button {
        font-size: 18px;
        padding: 5px 10px;
    }
}

@media only screen and (max-width: 450px) {
    .product-header {
        font-size: 20px;
    }
    .product-page__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product-page__container__column {
        font-size: 12px;
        align-self: start;
        margin-left: 5vw;
    }
    .product-image {
        width: 85vw;
        height: auto;
    }
    .zoom-button {
        display: none;
    }
    .product-image-small {
        width: 60px;
        height: 80px;
        margin-left: 1px;
    }
    .price-tag {
        font-size: 12px;
    }
    .cart-button {
        font-size: 18px;
        padding: 5px 10px;
    }
}
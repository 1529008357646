.terms-header {
    font-size: 28px;
    border-bottom: 2.5px solid #ce5a16;
    margin: 2vw;
    width: fit-content;
}

.terms-text {
    font-size: 18px;
    margin: 2vw;
}

.terms-text::first-letter {
    color: #d15812;
    font-size: 28px;
}

@media only screen and (max-width: 768px) {
    .terms-header {
        font-size: 16px;
        border-bottom: 2.5px solid #ce5a16;
        margin: 2vw;
        width: fit-content;
    }
    
    .terms-text {
        font-size: 14px;
        margin: 2vw;
    }

    .terms-text::first-letter {
        color: #d15812;
        font-size: 16px;
    }
}
/* Head.css */
.head {
  align-items: flex-end;
}

.headcontainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  padding: 20px;
}

.headcontainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../photos/menmiansan.png');
  background-size: cover; /* Image covers the entire container */
  background-position: center; /* Center the image within the container */
  opacity: 0.5; /* Adjust opacity as needed (0.0 to 1.0) */
  z-index: -1; /* Place the pseudo-element behind the content */
}


.logo {
  margin-top: 40px;
}

.logoimg {
  margin-top: 0px;
  margin-right: 30px;
  margin-left: 30px;
  max-width: 400px;
  height: auto;
}

.headimg {
  max-width: 400px;
  max-height: 50px;
  margin: 20px;
}

@media (max-width: 760px) {
  .head {
    text-align: left;
    align-items: center;
  }

  .headcontainer {
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }

  .headcontainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../photos/menmiansan.png');
    background-size: cover; /* Image covers the entire container */
    background-position: center; /* Center the image within the container */
    opacity: 0.5; /* Adjust opacity as needed (0.0 to 1.0) */
    z-index: -1; /* Place the pseudo-element behind the content */
  }

  .logo {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    
  }
  .logoimg {
    display: cover;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    width: 300px;
    height: auto;
    display: cover;
  }
  
  .headimg {
    max-width: 300px;
    height: auto;
    display: cover;
  }
}

@media (max-width: 400px) {
  .head {
    text-align: left;
    align-items: center;
  }

  .headcontainer {
    flex-direction: column;
    align-items: center;
    padding: 2px;
  }

  .logo {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    
  }
  .logoimg {
    display: cover;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    width: 200px;
    height: auto;
    display: cover;
  }
  
  .headimg {
    width: 200px;
    height: auto;
    display: cover;
  }
}
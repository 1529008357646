.footer-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #012306;
    padding: 1vw 2vw;
    border-radius: .5vw;
}

.footer-list__item {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
}

.footer-list__item:hover {
    color: rgb(228, 214, 8);
    transition: .5s;
}

@media only screen and (max-width: 768px) {
    .footer-list {
        height: 35px;
    }
    .footer-list__item {
        font-size: 15px;
    } 
}

@media only screen and (max-width: 330px) {
    .footer-list {
        height: 35px;
    }
    .footer-list__item {
        font-size: 12px;
    } 
}
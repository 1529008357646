body {
  font-family: 'Microsoft YaHei', 'PingFang SC', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  /* Add more fallback fonts if necessary */
}

.flexSB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #3D1301;
    padding: 1vw;
    margin-top: 1.5vw;
    border-radius: .5vw;
}

.header-list__item {
    text-decoration: none;
    color: #F5B060;
    font-size: 23px;
}

.header-list__item:hover {
    color: rgb(254, 254, 254);
    transition: .1s;
}


@media only screen and (max-width: 768px){
    .header-list__item {
        font-size: 18px;
    }
}

@media only screen and (max-width: 330px){
    .header-list__item {
        font-size: 12px;
    }
}
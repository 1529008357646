.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  input {
    border-radius: 20px;
    width: 70px;
    padding: 10px;
    outline: none;
  }
  .App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .container {
    width: 50vw;
    height: 30px;
    border-radius: 40px;
    border-style: none;
    background-color: #cdcdcd;
    color: #3D1301;
    position: relative;
    z-index: -1;
  }
  .innerContainer {
    height: 28px;
    display: grid;
    border-style: none;
    place-items: center;
    border-radius: 20px;
    margin: auto;
    background: linear-gradient(to left, #6f42c1 ,rgba(110, 66, 193, .7));
    box-shadow: 0 3px 3px -5px #6f42c1, 0 2px 5px #6f42c1;
    color: white;
    position: absolute;
    transition: 1.5s ease 0.3s;
    z-index: 100;
  }
  
.home-container {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}

.home-shop-button {
    background-color: #3D1301;
    color: #F5B060;
    font-size: 1.7vw;
    margin: 5px;
    padding: 1.3vw;
    border-radius: 30px;
    border: none;
    cursor: pointer;
}

.home-shop-button:active {
    background-color: rgb(255, 255, 255);
}

.products-section {
    text-align: center;
    margin-top: 5px;
}

.products-section__list {
    padding: 1vw;
    margin-bottom: 1vw;
    background: #f5b060a2;
    border-radius: .5vw;
    margin-top: 0vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

.products-section__list__item {
    width: 23%;
    height: 330px;
    background: #3D1301;
    padding: 0.5vw;
    border-radius: .5vw;
    margin: 3px;
    transition: .2s;
}

.products-section__list__item:hover {
    transform: scale(1.05);
}

.title-container {
    display: flex;
    justify-content: center;
}

.products-section__title {
    font-size: 28px;
    border-bottom: 3px solid #F5B060;
    width: fit-content;
}

.products-section__list__item__input {
    text-decoration: none;
    color: #fff;
    font-size: 1.5vw;
}

.products-section__list__item__image {
    width: 100%;
    height: 290px;
}

.products-section__list__item__price {
    font-size: 18px;
}

@media only screen and (max-width: 1055px) {
    .products-section__title {
        font-size: 23px;
    }
    .products-section__list__item {
        width: 30%;
        height: 275px;
        margin: 5px;
        padding: 1vw;
    }
    .products-section__list__item__image {
        width: 100%;
        height: 250px;
    }
    .home-shop-button {
        margin-top: 30px;
        font-size: 20px;
        padding: 10px 20px;
        margin-bottom: -20px;
    }
    .products-section__list__item__price {
        font-size: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .products-section__title {
        font-size: 23px;
    }
    .products-section__list__item {
        width: 45%;
        height: 260px;
        margin: 5px;
        padding: 1vw;
    }
    .products-section__list__item__image {
        width: 100%;
        height: 230px;
    }
    .home-shop-button {
        margin-top: 30px;
        font-size: 20px;
        padding: 10px 20px;
        margin-bottom: -20px;
    }
    .products-section__list__item__price {
        font-size: 15px;
    }
}

@media only screen and (max-width: 450px) {
    .products-section__title {
        font-size: 20px;
    }
    .products-section__list__item {
        width: 30%;
        height: 160px;
        margin: 2px;
        padding: 1vw;
    }
    .products-section__list__item__image {
        width: 100%;
        height: 130px;
    }
    .home-shop-button {
        margin-top: 30px;
        font-size: 13px;
        padding: 10px 20px;
        margin-bottom: -20px;
    }
    .products-section__list__item__price {
        font-size: 12px;
    }
}

@media only screen and (max-width: 390px) {
    .products-section__title {
        font-size: 18px;
    }
    .products-section__list__item {
        width: 45%;
        height: 160px;
        margin: 2px;
        padding: 1vw;
    }
    .products-section__list__item__image {
        width: 100%;
        height: 130px;
    }
    .home-shop-button {
        margin-top: 30px;
        font-size: 13px;
        padding: 10px 20px;
        margin-bottom: -20px;
    }
    .products-section__list__item__price {
        font-size: 12px;
    }
}
.contact-box {
    position: fixed;
    color: rgb(248, 241, 241);
    text-align: center;
    top: 320px;
    right: 15px;
    background-color: #024702c9;
    border: 2px solid #ccc;
    border-radius: 30px;
    padding: 25px 20px 10px 20px;
    box-shadow: 2px rgba(58, 56, 56, 0.1);
  }

  .contact-box img {
    width: 180px; /* Adjust the width as needed */
    height: 200px;
    border-radius: 8px;
    
  }

  .contactinfo {
    display: contents;
  }

  .contactinfo p {
    height: 20px;
  }

  @media only screen and (max-width: 768px) {
    .contact-box {
      position: fixed;
      color: rgb(248, 241, 241);
      text-align: center;
      top: 320px;
      right: 10px;
      background-color: #024702c9;
      border: 2px solid #ccc;
      border-radius: 30px;
      padding: 20px 10px 10px 10px;
      box-shadow: 2cm rgba(58, 56, 56, 0.1);
    }
      .contact-box img {
        width: 130px; /* Adjust the width as needed */
        height: 130px;
        border-radius: 8px;
        
      }
      .contactinfo p {
        height: 10px;
      }
  }

  @media only screen and (max-width: 250px) {
    .contact-box {
      position: fixed;
      color: rgb(248, 241, 241);
      text-align: center;
      top: 320px;
      right: 10px;
      background-color: #024702c9;
      border: 2px solid #ccc;
      border-radius: 30px;
      padding: 20px 5px 0px 5px;
      box-shadow: 2cm rgba(58, 56, 56, 0.1);
    }
      .contact-box img {
        width: 130px; /* Adjust the width as needed */
        height: 130px;
        border-radius: 8px;
        
      }
      .contactinfo p {
        height: 30px;
      }
  }